import { actionTypes } from './constants';
import { UserStateType, ActionType } from './types';
import { mapToInitialValues } from './utils';

/**
 * Reducer
 * Updated den STORE je nach ACTION type - Mitarbeiter der Auftrag ausfuehrt
 */
export const userReducer = (state: UserStateType, action: ActionType): UserStateType => {
  switch (action.type) {
    case actionTypes.SET_ALL:
      return {
        ...state,
        users: action.payload,
      };

    case actionTypes.TOGGLE_MULTISELECT:
      return {
        ...state,
        isMultiSelectMode: action.payload,
      };

    case actionTypes.CHANGE_MODE:
      return {
        ...state,
        viewMode: action.payload,
        currentUser: action.payload === 'new' ? undefined : state.currentUser,
        initialUserFormValues: action.payload === 'new' ? undefined : state.initialUserFormValues,
      };

    case actionTypes.SET_CURRENT:
      if (action.payload.data) {
        const currentUser = state.users.find((user) => user._id === action.payload._id);
        if (currentUser) {
          return {
            ...state,
            viewMode: 'view',
            currentUser,
            initialUserFormValues: mapToInitialValues(currentUser),
          };
        }
      }
      return {
        ...state,
        viewMode: 'view',
        currentUser: undefined,
        initialUserFormValues: undefined,
      };

    case actionTypes.UPDATE_CURRENT: {
      const currentUser = action.payload.data;
      return {
        ...state,
        currentUser,
        initialUserFormValues: mapToInitialValues(currentUser),
      };
    }

    default:
      return state;
  }
};
