import React, { ReactElement, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Formik, Form, FormikErrors } from 'formik';
import { Box } from '@material-ui/core';
import { PersonalFields } from './components/PersonalFields';
import { CompanyFields } from './components/CompanyFields';
import { ResetFormHelper } from '../actions/ResetFormHelper';
import { validateForm } from './validateForm';

import { attemptUpdateUser, attemptCreateUser } from '../../../../lib/store/user/effects';
import { transformToCreateUserType, transformToUpdateUserType } from '../../../../lib/context/user/utils';
import { initialValues } from './initialValues';
import { SecondaryTabBar } from '../../tab/SecondaryTabBar';
import { Submitbar } from './components/Submitbar';
import { useUserStateContext, useUserDispatchContext } from '../../../../lib/context/user';
import { UserFormValues } from '../../../../lib/context/user/types';
import { successUser } from '../../../../lib/store/user/actions';
import { RootState } from '../../../../lib/store';
import { hasAccountRequiredPermission } from '../../../../lib/utils';
import { AvailablePermissions } from '../../../../lib/constants/availablePermissions';

export { PersonalFields, ResetFormHelper, initialValues, validateForm };

export const UserForm = (): ReactElement => {
  const { t } = useTranslation(['module-core']);
  const { current: account } = useSelector((state: RootState) => state.account);
  const permissions = (account && account.role.permissions) || [];

  const hasDeletePermission = hasAccountRequiredPermission(permissions, [
    AvailablePermissions.CoreUseradministrationDelete,
  ]);

  const hasUpdatePermission = hasAccountRequiredPermission(permissions, [
    AvailablePermissions.CoreUseradministrationUpdate,
  ]);

  const { currentUser, successUser: success, viewMode, initialUserFormValues } = useUserStateContext();
  const dispatch = useUserDispatchContext();

  const disableAllFields = viewMode === 'edit' ? !hasUpdatePermission : false;

  const tabItems = [
    { label: t('module-core:pages.userManagement.tabs.personal') },
    { label: t('module-core:pages.userManagement.tabs.companySpecific') },
  ];
  const reduxDispatch = useDispatch();

  const handleSubmit = (values: UserFormValues): void => {
    if (viewMode === 'edit' && currentUser && currentUser._id) {
      reduxDispatch(attemptUpdateUser(currentUser._id, transformToUpdateUserType(values), dispatch));
    } else {
      reduxDispatch(attemptCreateUser(transformToCreateUserType(values)));
    }
  };

  const handleAdditionalAction = useCallback((): void => {
    reduxDispatch(successUser());
  }, [reduxDispatch]);

  return (
    <Box py={2} px={2}>
      <Formik
        initialValues={initialUserFormValues || initialValues}
        onSubmit={(values): void => {
          handleSubmit(values);
        }}
        validate={(values): FormikErrors<UserFormValues> => validateForm(values, t, viewMode)}
      >
        {(formikProps): ReactElement => {
          return (
            <Form>
              <ResetFormHelper reset={success === 'userAdd'} additionalAction={handleAdditionalAction} />
              <SecondaryTabBar variant="fullWidth" tabItems={tabItems}>
                <PersonalFields disableAllFields={disableAllFields} />
                {/* eslint-disable-next-line react/jsx-props-no-spreading */}
                <CompanyFields disableAllFields={disableAllFields} {...formikProps} />
              </SecondaryTabBar>
              <Submitbar hasDeletePermissions={hasDeletePermission} hasUpdatePermissions={hasUpdatePermission} />
            </Form>
          );
        }}
      </Formik>
    </Box>
  );
};
