import { instructionConstants } from './constants';
import { ProblemType } from '../../../../lib/store/types';
import { MetaDepartmentType, AreaType, MetaLocationType } from '../../../../lib/store/location/types';
import { StoredSlide } from '../../context/instructionEditor/types';
import { FileType } from '../../../../lib/store/file/types';

export type CreateInstructionType = {
  instructionName: string;
  instructionType: string;
  shortDescription: string;
  instructionTypeCategory: InstructionTypeCategory;
  previewImage?: string;
  createdBy: string;
  language: string;
  slides: InstructionSlideCreate[];
  assets?: InstructionAsset[];
};

export const isCreateInstructionType = (arg: Record<string, unknown>): arg is CreateInstructionType => {
  return (
    typeof arg.instructionName === 'string' &&
    typeof arg.instructionType === 'string' &&
    typeof arg.shortDescription === 'string' &&
    typeof arg.instructionTypeCategory === 'string' &&
    typeof arg.language === 'string' &&
    typeof arg.createdBy === 'string' &&
    Array.isArray(arg.slides)
  );
};

export type UpdateInstructionType = {
  instructionName: string;
  instructionType: string;
  shortDescription: string;
  instructionTypeCategory: InstructionTypeCategory;
  previewImage?: string;
  createdBy: string;
  language: string;
  slides: InstructionSlideUpdate[];
  assets?: InstructionAsset[];
};

export type AssignInstructionType = {
  _id?: string;
  intervalInMilliSeconds: number;
  startDate: Date | string;
  publicMeta?: InstructionPublicMeta;
  users?: string[];
  excludedUsers?: string[];
  employeeTypeNames?: string[];
  groupNames?: string[];
  locations?: string[];
  departments?: string[];
  areas?: string[];
};

export const isAssignInstructionType = (arg: Record<string, unknown>): arg is AssignInstructionType => {
  return typeof arg.intervalInMilliSeconds === 'number' && typeof arg.startDate === 'object';
};

export type InstructionVariant = {
  type: 'language' | 'version';
  instruction: string;
  data: string;
};

export type InstructionSlideUpdate = InstructionSlideCreate | InstructionSlide;

export type InstructionState = {
  instruction: Instruction | null;
  instructionList: Omit<
    Instruction & { translations?: InstructionTranslationMeta[]; firstSlide?: InstructionSlide },
    'slides'
  >[];
  totalInstructions: number;
  instructionStats: InstructionStat[];
  employeeStats: EmployeeStat[];
  instructionAssignment: AssignInstructionType | null;
  problem: ProblemType | null;
  loading: InstructionLoading;
  success: InstructionLoading;
};

export type InstructionLoading =
  | ''
  | 'instructionGet'
  | 'instructionListGet'
  | 'instructionStatsGet'
  | 'employeeStatsGet'
  | 'instructionCreate'
  | 'instructionAssignmentLoad'
  | 'instructionAssignmentSave'
  | 'instructionUpdate'
  | 'instructionDelete';

export type InstructionSlideCreate = {
  data: string;
} & ({ type: 'page'; questionnaire?: never } | { type: 'questionnaire'; questionnaire: QuestionnaireDataType[] });

export type InstructionSlide = InstructionSlideCreate & {
  _id: string;
};

export type InstructionPublicMeta = {
  viewToken: string;
  isActive: boolean;
};

export type InstructionTypeCategory = '' | 'visitor' | 'external';
export type MetaFormValueType = {
  instructionName: string;
  instructionType: string;
  instructionTypeCategory: InstructionTypeCategory;
  shortDescription: string;
  language: string;
  createdBy: string;
  createdAt?: Date;
  lastEditedBy?: string;
  lastEditedAt?: Date;
  previewImage?: string;
};

export type QuestionnaireDataType = {
  question: string;
  multipleAnswersPossible: boolean;
  answers: QuestionnaireAnswerType[];
};

export type QuestionnaireAnswerType = {
  answer: string;
  isCorrect: boolean;
};
export type InstructionSlideType = 'page' | 'questionnaire';
export type TranslationsAvailable = 'de-DE' | 'en-GB' | 'pl-PL' | 'es-ES' | 'fr-FR' | 'ru-RU' | 'cz-CZ';

export type InstructionTranslationMeta = { _id: string; language: TranslationsAvailable };

export type InstructionAsset = {
  viewId: string;
  asset: string | FileType;
  translationId?: string;
  slideIndex?: number;
};
export type InstructionMeta = {
  _id: string;
  isDeactivated: boolean;
  isExample?: boolean;
  readingTime?: string;
  tutorialTypeCategory?: string;
  createdBy: { _id: string; firstName?: string; lastName?: string };
  createdAt: Date;
  instructionName: string;
  instructionType: string;
  instructionTypeCategory?: InstructionTypeCategory;
  shortDescription?: string;
  previewImage: string;
  variants?: InstructionVariant[];
  language: TranslationsAvailable;
  translations?: InstructionTranslation[];
  sortTutorials?: string;
};

export type PutInstructionTranslation = Omit<
  InstructionTranslation,
  'createdAt' | '_id' | 'slides' | 'organisation'
> & {
  _id?: string;
  createdAt?: Date;
  slides: StoredSlide[];
};

export type InstructionTranslation = {
  _id: string;
  parent: string;
  organisation: string;
  language: TranslationsAvailable;
  overwrites?: {
    instructionName: string;
    instructionType: string;
  };
  createdBy: { _id: string; firstName?: string; lastName?: string } | string;
  createdAt: Date;
  lastEditedBy?: { _id: string; firstName?: string; lastName?: string } | string;
  lastEditedAt?: Date;
  slides: InstructionSlide[];
};

export type Instruction = InstructionMeta & {
  lastEditedBy?: { _id: string; firstName?: string; lastName?: string };
  lastEditedAt?: Date;
  assignment?: AssignInstructionType;
  slides: InstructionSlide[];
  assets?: InstructionAsset[];
};

export type InstructionMetaResponse = {
  instructions: Omit<
    Instruction & { translations?: InstructionTranslationMeta[]; firstSlide?: InstructionSlide },
    'slides'
  >[];
  totalInstructions: number;
};

export type EmployeeStat = {
  _id: string;
  organisation: string;
  instructionId: string;
  instructionName: string;
  location?: MetaLocationType;
  department?: MetaDepartmentType;
  area?: AreaType;
  firstName: string;
  lastName: string;
  nextDue: string;
  createdByFirstName?: string;
  createdByLastName?: string;
  lastCompleted?: string;
  isMinor?: boolean;
};

export type InstructionStatus = {
  yellowCount: number;
  redCount: number;
  greenCount: number;
};

export type InstructionStat = InstructionStatus & {
  instructionStats: InstructionStats[];
};

export type InstructionStats = InstructionStatRaw & InstructionStatus;

export type InstructionStatRaw = Omit<Instruction, 'slides'> & {
  assignedUserCounts: { nextDue: string }[];
};

export type ActionType =
  | { type: instructionConstants.INSTRUCTIONS_LOADING; payload: InstructionLoading }
  | { type: instructionConstants.INSTRUCTIONS_ERRORED; payload: ProblemType | null }
  | { type: instructionConstants.INSTRUCTIONS_SUCCESS; payload: InstructionLoading }
  | {
      type: instructionConstants.INSTRUCTIONS_SET;
      payload: Instruction | null;
    }
  | {
      type: instructionConstants.INSTRUCTIONS_SET_ALL_STATS;
      payload: InstructionStats[];
    }
  | {
      type: instructionConstants.INSTRUCTIONS_SET_ALL_EMPLOYEE_STATS;
      payload: EmployeeStat[];
    }
  | {
      type: instructionConstants.INSTRUCTIONS_SET_ASSIGNMENT;
      payload: AssignInstructionType | null;
    }
  | { type: instructionConstants.INSTRUCTIONS_SET_ALL; payload: InstructionMetaResponse }
  | { type: instructionConstants.INSTRUCTIONS_INIT }
  | { type: 'dSimple/RESET_APP' };
